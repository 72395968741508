import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchUserList(params) {
  return axios.get(baseUrl + `/api/admin/user/list`, { params, headers });
}

export function fetchUserDetails(id) {
  return axios.get(baseUrl + `/api/admin/user/${id}`, { headers });
}

export function upadateUserDetails(data) {
  return axios.put(baseUrl + `/api/admin/user`, data, { headers });
}

export function removeUser(id) {
  return axios.delete(baseUrl + `/api/admin/user/${id}`, { headers });
}

export function fetchDashCount() {
  return axios.get(baseUrl + `/api/admin/dashboard`, { headers });
}

export function fetchUserLikesList(params) {
  return axios.get(baseUrl + `/api/admin/user/likes`, { params, headers });
}

// 강의
export function createLecture(data) {
  return axios.post(baseUrl + `/api/admin/lecture`, data, { headers });
}
export function fetchLectureList(params) {
  return axios.get(baseUrl + `/api/admin/lecture/list`, { params, headers });
}
export function fetchLecture(id) {
  return axios.get(baseUrl + `/api/lecture/${id}`, { headers });
}
export function updateLecture(data) {
  return axios.put(baseUrl + `/api/admin/lecture`, data, { headers });
}

export function removeLecture(data) {
  return axios.put(baseUrl + `/api/admin/lecture/delete`, data, {
    headers,
  });
}

// 지원서
export function fetchApplicationList(params) {
  return axios.get(baseUrl + `/api/admin/application/list`, {
    params,
    headers,
  });
}
export function fetchApplication(id) {
  return axios.get(baseUrl + `/api/application/${id}`, { headers });
}

export function updateApplication(data) {
  return axios.put(baseUrl + `/api/admin/application/result`, data, {
    headers,
  });
}

export function removeApplication(id) {
  return axios.delete(baseUrl + `/api/admin/application/${id}`, { headers });
}

// 견적서
export function createEstimate(data) {
  return axios.post(baseUrl + `/api/estimate`, data, { headers });
}
export function fetchEstimateList(params) {
  return axios.get(baseUrl + `/api/admin/estimate/list`, { params, headers });
}
export function fetchEstimate(id) {
  return axios.get(baseUrl + `/api/estimate/${id}`, { headers });
}
export function updateEstimate(data) {
  return axios.put(baseUrl + `/api/estimate`, data, { headers });
}
export function replyEstimate(data) {
  return axios.post(baseUrl + `/api/estimate/reply`, data, { headers });
}
export function removeEstimate(id) {
  return axios.delete(baseUrl + `/api/estimate/${id}`, { headers });
}
// 결제
export function fetchOrderList(params) {
  return axios.get(baseUrl + `/api/admin/order/list`, { params, headers });
}
export function fetchOrder(id) {
  return axios.get(baseUrl + `/api/admin/order/${id}`, { headers });
}
export function createRefund(data) {
  return axios.post(baseUrl + `/api/admin/order/refund`, data, { headers });
}

export function fetchEstimateForAdmin(id) {
  return axios.get(baseUrl + `/api/admin/estimate/${id}`, { headers });
}

// QnA
export function fetchQnAList(params) {
  return axios.get(baseUrl + `/api/admin/qna/list`, { params, headers });
}
export function fetchQnADetail(id) {
  return axios.get(baseUrl + `/api/admin/qna/${id}`, { headers });
}
export function upadateQnAReply(data) {
  return axios.put(baseUrl + `/api/admin/qna`, data, { headers });
}
export function removeQnA(id) {
  return axios.delete(baseUrl + `/api/admin/qna/${id}`, { headers });
}

// 배너관리
export function createBanner(data) {
  return axios.post(baseUrl + `/api/admin/banner`, data, { headers });
}
export function fetchBanner() {
  return axios.get(baseUrl + `/api/admin/banner`, { headers });
}
export function updateBanner(data) {
  return axios.put(baseUrl + `/api/admin/banner`, data, { headers });
}
export function deleteBanner(params) {
  return axios.delete(baseUrl + `/api/admin/banner`, { params, headers });
}
// 리뷰관리
export function fetchReview(params) {
  return axios.get(baseUrl + `/api/admin/review/list`, { params, headers });
}
export function updateReview(data) {
  return axios.put(baseUrl + `/api/admin/review`, data, { headers });
}
export function fetchReviewDetail(id) {
  return axios.get(baseUrl + `/api/admin/review/${id}`, { headers });
}
export function deleteReview(id) {
  return axios.delete(baseUrl + `/api/admin/review/${id}`, { headers });
}

// 스케쥴 관리
export function fetchScheduleList(params) {
  return axios.get(baseUrl + `/api/admin/schedule/list`, { params, headers });
}
export function createSchedule(data) {
  return axios.post(baseUrl + `/api/admin/schedule`, data, { headers });
}
export function fetchScheduleDetail(id) {
  return axios.get(baseUrl + `/api/schedule/${id}`, { headers });
}
export function updateSchedule(data) {
  return axios.put(baseUrl + `/api/admin/schedule`, data, { headers });
}
export function removeSchedule(id) {
  return axios.delete(baseUrl + `/api/schedule/${id}`, { headers });
}
export function findProviderByUserId(params) {
  return axios.get(baseUrl + `/api/admin/find/planner`, { params, headers });
}
export function confirmSchedule(data) {
  return axios.put(baseUrl + `/api/admin/schedule/confirm`, data, { headers });
}

// 공지사항
export function createNotice(data) {
  return axios.post(baseUrl + `/api/admin/notice`, data, { headers });
}
export function fetchNoticeList(params) {
  return axios.get(baseUrl + `/api/admin/notice/list`, { params, headers });
}
export function fetchNotice(id) {
  return axios.get(baseUrl + `/api/notice/${id}`, { headers });
}
export function updateNotice(data) {
  return axios.put(baseUrl + `/api/admin/notice`, data, { headers });
}
export function removeNotice(id) {
  return axios.delete(baseUrl + `/api/admin/notice/${id}`, { headers });
}

//
export function createBeforeAndAfter(data) {
  return axios.post(baseUrl + `/api/admin/bnf`, data, { headers });
}
export function fetchBeforeAndAfterDetail(id) {
  return axios.get(baseUrl + `/api/admin/bnf/${id}`, { headers });
}
export function upadateBeforeAndAfter(data) {
  return axios.put(baseUrl + `/api/admin/bnf`, data, { headers });
}

export function revomeBeforeAndAfter(data) {
  return axios.put(baseUrl + `/api/admin/bnf/delete`, data, { headers });
}

export function fetchBeforeAndAfterListById(id) {
  return axios.get(baseUrl + `/api/admin/provider/${id}/bnf/list`, { headers });
}

export function fetchBeforeAndAfterList() {
  return axios.get(baseUrl + `/api/provider/bnf/list`, { headers });
}
